<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import Aws from "@/services/Aws";
import modalAddScheduledAction from "@/components/modals/monitor/aws/modalAddScheduledAction";
import Swal from "sweetalert2";

export default {
    components: { Layout, PageHeader, modalAddScheduledAction },
    data() {
        return {
            title: "AWS Scheduler",
            error: null,
            tableData: [],
            currentPage: 1,
            perPage: 10,
            items: [
                {
                    text: "AWS",
                },
                {
                    text: "AWS Scheduler",
                    active: true,
                },
            ] ,
            pageOptions: [10, 25],
            filter: null,
            filterOn: [],
            sortBy: "created_at",
            sortDesc: false,
            isBusy: false,
            showAllSchedules:0,
            applications: [],
            fields: [
                {
                    key: "application_name",
                    label: "Application Name",
                    sortable: true,
                },
                {
                    key: "action_name",
                    label: "Action Name",
                    sortable: false,
                },
                {
                    key: "input",
                    label: "Input",
                    sortable: false,
                },
                {
                    key: "start_at",
                    label: "Scheduled",
                    sortable: false,
                },
                {
                    key: "end_at",
                    label: "Completed",
                    sortable: false,
                },
                {
                    key: "status",
                    label: "Status",
                    sortable: false,
                },
                {
                    key: "created_at",
                    label: "Created At",
                    sortable: true,
                },
                "action",
            ],
            totalRows: 0,
            showModal: false
        };
    },

    async mounted() {
        await this.getScheduledActions()
        await this.getApplications()
    },
    methods: {
        cleanItem(item) {
            return item
                .replace(/[{}"]/g, '')  // Removes { } and "
                .replace(/,/g, '<br>');  // Replaces commas with <br>
        },
        async getScheduledActions() {
            try {
                this.isBusy = true;
                let filterData = '';
                const response = await Aws.getScheduledActions(filterData);
                this.originalTableData = response.data.data;

                let filteredData = [...this.originalTableData];
                let dateToday = new Date();
                let dateOneDayAgo = new Date(dateToday);
                dateOneDayAgo.setDate(dateToday.getDate() - 1);
                filteredData = filteredData.filter(item => {
                    return (new Date(item.start_at) > dateToday) ||
                        (new Date(item.end_at) > dateOneDayAgo) &&
                        (item.status === 'completed' || item.status === 'failed'); // Check status
                });

                this.tableData = [...filteredData];
                this.totalRows = filteredData.length;
            } catch (error) {
                this.error = error.response.data.error ? error.response.data.error : "";
                this.tableData = [];
                this.totalRows = 0;
            } finally {
                this.showLoader = false;
                this.isBusy = false;
            }
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        addScheduledAction(){
            this.$bvModal.show("add_ScheduledAction");
        },

        async getApplications() {
            try {
                this.showLoader = true;
                const response = await Aws.getApplications();
                this.applications = response.data.data;
            } catch (error) {
                this.error = error.response.data.error ? error.response.data.error : "";
            }finally {
                this.showLoader = false;
            }
        },

        removeScheduledAction(id) {
            Swal.fire({
                title: "Are you sure you want to remove scheduled action?",
                text: "",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes",
            }).then((result) => {
                if (result.value) {
                    Aws.removeScheduledAction(id)
                        .then((response) => {
                            const res = response.data.data ? response.data.data : false;
                            const error = response.data.error ? response.data.error : "";
                            if (res && error == "") {
                                this.successmsg("Successfully Removed");
                                this.getScheduledActions();
                            } else {
                                this.failedmsg(error);
                            }
                        })
                        .catch((error) => {
                            this.failedmsg("Fail!", error);
                        });
                }
            });
        },

        applyFilters() {
            let filteredData = [...this.originalTableData];

            if (this.showAllSchedules) {
                this.tableData = filteredData;
            } else {
                let dateToday = new Date();
                let dateOneDayAgo = new Date();
                dateOneDayAgo.setDate(dateOneDayAgo.getDate() - 1);

                filteredData = filteredData.filter(item => {
                    return (new Date(item.start_at) > dateToday) ||
                        (new Date(item.end_at) > dateOneDayAgo) &&
                        (item.status === 'completed' || item.status === 'failed'); // Check status
                });

                this.tableData = filteredData;
            }

            this.totalRows = this.tableData.length;
        }

    },
    middleware: "authentication",
};
</script>
<template>
    <Layout>
        <PageHeader :title="title" :items="items" />

        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-4">
                                <a href="javascript:void(0);" class="btn btn-success waves-effect waves-light"
                                   @click="addScheduledAction">
                                    <i class="mdi mdi-plus me-2"></i>Add Scheduled Action
                                </a>
                            </div>
                            <div class="col-sm-4">
                                <b-form-checkbox-group>
                                    <div class="form-check form-switch form-switch-lg mb-3">
                                        <input v-model="showAllSchedules" @change="applyFilters"
                                               type="checkbox"
                                               class="form-check-input"
                                               id="showAllSchedules"
                                        />
                                        <label class="form-check-label" for="showAllSchedules">Show All Scheduled Actions</label>
                                    </div>
                                </b-form-checkbox-group>
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-sm-12 col-md-3">
                                <div class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Show&nbsp;
                                        <b-form-select
                                                v-model="perPage"
                                                size="sm"
                                                :options="pageOptions"
                                        ></b-form-select
                                        >&nbsp;entries
                                    </label>
                                </div>
                            </div>

                            <!-- Search -->
                            <div class="col-sm-12 col-md-9">
                                <div
                                        id="tickets-table_filter"
                                        class="dataTables_filter text-md-end"
                                >
                                    <label class="d-inline-flex align-items-center">
                                        Search:
                                        <b-form-input
                                                v-model="filter"
                                                type="search"
                                                placeholder="Search..."
                                                class="form-control form-control-sm ms-2"
                                        ></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0">
                            <b-table
                                    :busy="isBusy"
                                    :items="tableData"
                                    :fields="fields"
                                    responsive="sm"
                                    :per-page="perPage"
                                    :current-page="currentPage"
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                                    :filter="filter"
                                    :filter-included-fields="filterOn"
                                    show-empty
                                    empty-text="No Data Found"
                                    @filtered="onFiltered"
                            >

                                <template v-slot:cell(action_name)="data">
                                    <span v-if="data.item.action_name == 'update_auto_scaling_group'">Application Servers</span>
                                    <span v-if="data.item.action_name == 'read_db_instance'">Database Reader Instances</span>
                                </template>

                                <template v-slot:cell(input)="data">

                                    <div v-html="cleanItem(data.item.input)"></div>
                                </template>

                                <template v-slot:cell(action)="data">
                                    <ul class="list-inline mb-0">
                                        <li class="list-inline-item">
                                            <a href="#" class="text-danger" @click="removeScheduledAction(data.item.id)" title="Remove Scheduled Action">
                                                <i class="uil uil-trash-alt font-size-18"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </template>

                                <template #table-busy>
                                    <div class="text-center text-danger my-2">
                                        <b-spinner class="align-middle"></b-spinner>
                                        <strong>Loading...</strong>
                                    </div>
                                </template>

                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-end">
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination
                                                v-model="currentPage"
                                                :total-rows="totalRows"
                                                :per-page="perPage"
                                        ></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <modalAddScheduledAction
                :applicationsData="applications"
                @onRefresh="getScheduledActions"
        ></modalAddScheduledAction>

    </Layout>
</template>
